import React, { useRef, useEffect, useMemo, useState } from 'react';
import { reduxForm, Field, initialize } from 'redux-form';
import { CheckBox, View, Image, TouchableOpacity, Modal } from 'react-native';

import Actions from '../../RouteActions';
import { apiSeverUrl } from '../../services/baseService';
import { connect } from 'react-redux';
import { parse } from 'search-params';
import * as AsyncStorage from '../../AsyncStorage';
import { logout } from '../Settings/actions';

//layout
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';

//component
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import FormInput from '../../components/FormInput/FormInput';
import Text from '../../components/Text/Text';
import CloseButton from '../../components/CloseButton/CloseButton';

//images
import { withGetScreen } from 'react-native-getscreen';
import appleIcon from '../../assets/svg/apple-icon.svg';
import microsoftIcon from '../../assets/svg/microsoft-icon.svg';
import googleIcon from '../../assets/svg/google-icon1.svg';
import unionIcon from '../../assets/svg/union-icon.svg';

//styles
import styles from './styles';
import { formLayout } from '../../generalStyles';

import * as actions from './actions';
import * as signinActions from '../SignInEmail/actions';

//utils
import { notification, validateEmail } from '../../helperFunctions';
import * as redirectActions from '../../globalActions/redirectActions';
import BrowserLinkText from '../../components/BrowserLinkText/BrowserLinkText';
import LinkedInSsoLogin from '../LinkedinSsoLogin/LinkedinSsoLogin';
import GoogleSSOLogin from '../GoogleSSOLogin/GoogleSSOLogin.web';
import GoogleSSOButton from '../GoogleSSOLogin/GoogleSSOButton';
import { updateStepsOnBackButton } from '../SignUpPhone/actions';
import { debounce } from 'lodash';
import SSOModal from '../../components/SSOModal/SSOModal';

const SignUp = (props) => {
  const {
    verifyInviteToken,
    currentStep,
    location,
    formValues,
    signUpLoading,
    inviteData,
    initialValues,
    orgName,
    invitedByUserId,
    clearInviteData,
    getInviteData,
    initializeForm,
    onSubmit,
    onGoBackSignUp,
    onUpdateSignUp,
    organization,
    loginUser,
    redirect,
    isMobile,
    verifySsoEnabled,
    currentUser,
    onGoBackLogin,
  } = props;
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [accepted, setAccepted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showSsoModal, setShowSsoModal] = useState(false);
  const [emialDisabled, setEmialDisabled] = useState(false);
  const [SsoEmail, setSsoEmail] = useState(false);
  const [userPresent, setUserPresent] = useState(false);
  const formInitialValues = useMemo(() => {
    return (initialValues || inviteData || {}).user_data || {};
  }, [initialValues, inviteData]);

  useEffect(() => {
    if (currentStep === 'phone_number') {
      onGoBackSignUp();
    }
  }, []);

  useEffect(
    () =>
      window.addEventListener('popstate', (e) => {
        if (Object.keys(currentUser).length) {
          onGoBackLogin();
        }
        return true;
      }),
    []
  );

  useEffect(() => () => window.removeEventListener('popstate', (e) => {}), []);

  useEffect(() => {
    const init = async () => {
      try {
        const urlParams = parse(location.search);
        if (urlParams.token) {
          return verifyInviteToken(urlParams.token);
        }

        if (urlParams.X_User_Token) {
          return loginUser(urlParams.X_User_Token);
        }

        if (urlParams.invitation_token) {
          getInviteData(urlParams.invitation_token);
        }

        const accessToken = await AsyncStorage.getAccessToken();
        if (typeof accessToken === 'string' && accessToken !== '' && currentStep == 'home') {
          redirect();
        }
      } catch (err) {}
    };

    init();

    return () => {
      clearInviteData();
    };
  }, []);

  useEffect(() => {
    initializeForm({
      email: formInitialValues.email,
      first_name: formInitialValues.first_name,
      last_name: formInitialValues.last_name,
    });
  }, [formInitialValues]);

  useEffect(() => {
    if (Object.entries(currentUser).length) {
      initializeForm({
        email: currentUser.email,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
      });

      if (currentUser.email) {
        setEmialDisabled(true);
      }
      setAccepted(true);
      setUserPresent(true);
    }
  }, [currentUser]);

  useEffect(() => {
    const debouncedVerify = debounce(() => {
        verifySsoEnabled({ email: formValues.email }, () => setSsoEmail(true), () => setSsoEmail(false));
    }, 500); 

    debouncedVerify();

    return () => {
        debouncedVerify.cancel(); 
    };
}, [formValues.email]);

  const outlookSsoOauth = () => {
    let form = document.createElement('form');
    form.method = 'POST';
    form.action = `${apiSeverUrl}/auth/microsoft_graph_auth?sso_signup=${true}`;
    document.body.appendChild(form);
    form.submit();
  };

  const handleBacktoLogin = () => {
    if (Object.keys(currentUser).length) {
      onGoBackLogin();
    }
    Actions.signInEmail();
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!accepted) {
      notification.ref.show({
        message: 'Please read and accept the Terms of Service',
        isError: true,
      });
      return;
    }

    if (!formValues.first_name || !formValues.last_name || !formValues.email || !formValues.password) {
      notification.ref.show({
        message: 'Please fill all mandatory fields',
        isError: true,
      });
      return;
    }
    formValues.orgName = orgName;
    formValues.invitedBy = invitedByUserId;
    formValues.first_name = formValues.first_name.replace(/\s/g, '');
    formValues.last_name = formValues.last_name.replace(/\s/g, '');
    if (userPresent) {
      onUpdateSignUp(formValues);
    } else {
      onSubmit(formValues, organization);
    }
  };

  const disabled =
    signUpLoading ||
    !accepted ||
    !formValues.first_name ||
    !formValues.first_name.trim() ||
    !formValues.last_name ||
    !formValues.last_name.trim() ||
    !formValues.email ||
    !validateEmail(formValues.email) ||
    !formValues.password ||
    !formValues.password.trim() ||
    SsoEmail

  return (
    <AuthLayout>
      <View>
        <View style={styles.formWrapper}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Quick Sign Up</Text>
          </View>
          <View>
            <View style={styles.formFieldWrapper}>
              <Field
                component={FormInput}
                name="first_name"
                maxLength={20}
                placeholder="First name *"
                wrapperStyle={[formLayout.fullWidth]}
                labelStyle={styles.labelStyle}
                inputStyle={styles.inputStyle}
                onChangeText={(e) => setFirstName(e)}
                onSubmitEditing={() => lastNameRef.current.focus()}
                returnKeyType="next"
                autoFocus
              />
            </View>
            <View style={styles.formFieldWrapper}>
              <Field
                component={FormInput}
                name="last_name"
                maxLength={20}
                placeholder="Last name *"
                wrapperStyle={[formLayout.fullWidth]}
                labelStyle={styles.labelStyle}
                inputStyle={styles.inputStyle}
                onChangeText={(e) => setLastName(e)}
                inputRef={(ref) => (lastNameRef.current = ref)}
                onSubmitEditing={() => emailRef.current && emailRef.current.focus()}
                returnKeyType="next"
              />
            </View>
            <View style={styles.formFieldWrapper}>
              <Field
                component={FormInput}
                name="email"
                disabled={(Boolean(inviteData) && Boolean(formInitialValues.email)) || emialDisabled}
                placeholder="Email *"
                wrapperStyle={[formLayout.fullWidth]}
                labelStyle={styles.labelStyle}
                inputStyle={styles.inputStyle}
                inputRef={(ref) => (emailRef.current = ref)}
                returnKeyType="next"
                keyboardType="email-address"
                onBlur={() => {
                  if (validateEmail(formValues.email)) verifySsoEnabled({ email: formValues.email }, ()=>setShowSsoModal(true), ()=>{});
                }}
                onSubmitEditing={() => passwordRef.current && passwordRef.current.focus()}
              />
            </View>
          </View>
          <View style={styles.formFieldWrapper}>
            <Field
              component={FormInput}
              name="password"
              placeholder="Password *"
              secureTextEntry
              inputStyle={styles.inputStyle}
              labelStyle={styles.labelStyle}
              wrapperStyle={[formLayout.fullWidth]}
              inputRef={(ref) => (passwordRef.current = ref)}
              returnKeyType="send"
              visibilityIconButtonStyle={{
                right: 14,
                bottom: 20,
              }}
            />
          </View>
          <View style={styles.formFieldWrapper}>
            <View style={{ height: 84, paddingTop: 18.5 }}>
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <View
                  style={{
                    backgroundColor: '#C4C4C4',
                    width: 10,
                    height: 1,
                    marginRight: 7,
                  }}
                />
                <Text style={{ color: '#000000', fontSize: 12 }}> OR </Text>
                <View
                  style={{
                    backgroundColor: '#C4C4C4',
                    width: 10,
                    height: 1,
                    marginLeft: 7,
                  }}
                />
              </View>
              <View
                style={{
                  marginTop: 12,
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  margin: 'auto',
                  flexDirection: 'row',
                  width: 270,
                }}
                className="SSO-Questions-wrapper"
              >
                <TouchableOpacity style={styles.iconButton} onPress={() => outlookSsoOauth()}>
                  <Image
                    source={microsoftIcon}
                    resizeMode="contain"
                    style={{
                      width: 19,
                      height: 15,
                    }}
                  />
                </TouchableOpacity>
                <GoogleSSOButton />
                <LinkedInSsoLogin />
                <TouchableOpacity style={styles.iconButton} onPress={() => Actions.signInSSO()}>
                  <Image source={unionIcon} resizeMode="contain" style={{ width: 19, height: 10 }} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={styles.checkboxWrapper}>
            <CheckBox value={accepted} onValueChange={setAccepted} style={styles.checkbox} />
            <View>
              <View style={styles.termAndConditionsParent}>
                <Text style={styles.termAndConditionsChild}>I have read and accepted the&nbsp;</Text>
                <TouchableOpacity>
                  <Text style={styles.termAndConditionsChild}>
                    <BrowserLinkText url={'https://goboon.co/terms'}>
                      <Text style={styles.termAndConditionsText}>Terms of Service</Text>
                    </BrowserLinkText>
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.termAndConditionsParent}>
                <TouchableOpacity>
                  <Text style={styles.termAndConditionsChild}>
                    &&nbsp;&nbsp;
                    <BrowserLinkText url={'https://goboon.co/privacy'}>
                      <Text style={styles.termAndConditionsText}>Privacy Policy.</Text>
                    </BrowserLinkText>
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={styles.formFieldWrapper}>
            <PrimaryButton
              onPress={submitForm}
              disabled={disabled}
              style={disabled ? styles.disabledButton : styles.button}
              textStyle={disabled ? styles.disabledButtonText : styles.buttonText}
            >
              SIGN UP
            </PrimaryButton>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Text style={{ color: '#FFFFFF', fontSize: 12 }}>Already have an account?&nbsp;</Text>
          <TouchableOpacity onPress={handleBacktoLogin}>
            <Text
              style={{
                color: '#FFFFFF',
                fontSize: 12,
                textDecorationLine: 'underline',
              }}
            >
              <b>Login</b>
            </Text>
          </TouchableOpacity>
        </View>
        <SSOModal showSsoModal={showSsoModal} setShowSsoModal={setShowSsoModal}/>
      </View>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: (state.form.signUp && state.form.signUp.values) || {},
    invitedByUserId: state.authReducer.invitedByUserId,
    signUpLoading: state.authReducer.signUpLoading,
    inviteData: state.authReducer.inviteData,
    currentStep: state.stepsReducer.step,
    currentUser: state.authReducer.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (formValues, org) => {
      if (formValues.user_data !== undefined && formValues.user_data !== null) {
        formValues.joined_by_invitation = formValues.user_data.joined_by_invitation;
        formValues.account_confirmation = true;
        delete formValues.user_data;
      }
      if(org){
        dispatch(actions.signUp(formValues,org)); 
      }else{
      dispatch(actions.signUp(formValues));
      }
    },
    onUpdateSignUp: (formValues) => {
      dispatch(actions.updateSignupParams(formValues));
    },
    verifyInviteToken: (params) => {
      dispatch(actions.verifyInviteToken(params));
    },
    verifySsoEnabled: (params, setShowSsoModal , enableSignin) => {
      dispatch(actions.verifySsoEnabled(params, setShowSsoModal , enableSignin));
    },
    loginUser: (token) => {
      dispatch(signinActions.loginUserWithToken(token));
    },
    getInviteData: (params) => {
      dispatch(actions.getInviteData(params));
    },
    initializeForm: (values) => {
      dispatch(initialize('signUp', values));
    },
    redirect: () => {
      dispatch(redirectActions.redirect());
    },
    clearInviteData: () => {
      dispatch(actions.clearInviteData());
    },
    onGoBackSignUp: () => {
      dispatch(
        updateStepsOnBackButton({
          current: 'phone_number',
          new: 'signup',
        })
      );
    },
    onGoBackLogin: () => {
      dispatch(logout());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'signUp', enableReinitialize: true })(withGetScreen(SignUp)));
